<!-- //科技管理 -->
<template>
  <div>
    <el-row>
      <el-col :span="6" class="flex-row">
        <span class="span1">种植季名称</span>
        <el-input v-model="searchParams.name" style="width: 60%" placeholder="请输入内容" clearable></el-input>
      </el-col>
      <!-- <el-col :span="6" class="flex-row">
        <span class="span1">创建时间</span>
        <el-date-picker style="width: 60%;" v-model="startDate" type="datetimerange" range-separator="-"
          start-placeholder="开始日期" end-placeholder="结束日期" clearable>
        </el-date-picker>
                                                                                                                                                                                                                                  </el-col> -->
      <el-col :span="6" class="flex-row">
        <span class="span1">状态</span>
        <el-select style="width: 60%" v-model="searchParams.state" placeholder="请选择" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6" class="flex-row">
        <el-button type="primary" @click="search">搜索</el-button>
      </el-col>
    </el-row>
    <el-row type="flex" justify="end" style="margin-bottom: 12px">
      <el-button type="primary" size="small" @click="(isEdit = false), (isDisable = false), (dialogVisible = true)">新建种植方案</el-button>
    </el-row>

    <el-table :data="tableData" stripe style="width: 100%" header-cell-class-name="header-cell-bg" header-row-class-name="header-bg">
      <template slot="empty">
        <img style="width: 80%; height: 100%" src="@/assets/empty.jpeg" alt="" />
      </template>
      <el-table-column fixed prop="name" label="种植方案名称"> </el-table-column>
      <el-table-column prop="shortTitle" label="方案亮点"> </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          {{ geetStatus(scope.row.state) }}
        </template>
      </el-table-column>
      <el-table-column prop="descriptions" label="描述" show-overflow-tooltip> </el-table-column>
      <el-table-column prop="cropsACategoryName" label="种植作物"> </el-table-column>
      <el-table-column prop="cropsAName" label="作物品种"> </el-table-column>
      <el-table-column prop="soilProperties" label="土壤特性" show-overflow-tooltip> </el-table-column>
      <el-table-column label="预估亩产">
        <template slot-scope="scope"> {{ scope.row.cropsAPredictYield || '-' }}{{ scope.row.cropsAPredictYield ? '斤' : '' }} </template>
      </el-table-column>
      <el-table-column label="关联种植季">
        <template slot-scope="scope">
          {{ getSeasonDetail(scope.row.seasonId) }}
        </template>
      </el-table-column>
      <el-table-column prop="schemeFarmNums" label="关联农事操作">
        <template slot-scope="scope">
          {{ scope.row.schemeFarmNums || '-' }}
          <!-- <el-button v-if="scope.row.state != 2" type="text" size="small" @click="toFarming(scope.row)">编辑</el-button> -->
        </template>
      </el-table-column>
      <!-- <el-table-column prop="schemeLandNums" label="关联地块">
        <template slot-scope="scope">
          {{ scope.row.schemeLandNums || '-' }}
          <el-button v-if="scope.row.state == 2" type="text" size="small" @click="toLand(scope.row)">编辑</el-button>
        </template>
      </el-table-column> -->
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-popconfirm
            v-if="scope.row.state == 1 || scope.row.state == 3"
            title="确认启用吗？发布后将展示在手机端，用户可进行查看"
            @confirm="publish(scope.row)"
          >
            <el-button slot="reference" type="text" size="small">启用</el-button>
          </el-popconfirm>
          <el-button v-if="scope.row.state == 1 || scope.row.state == 3" slot="reference" type="text" size="small" @click="toEdit(scope.row)"
            >修改</el-button
          >
          <el-popconfirm v-if="scope.row.state == 1 || scope.row.state == 3" title="确认删除?" @confirm="deletePlant(scope.row)">
            <el-button slot="reference" type="text" size="small">删除</el-button>
          </el-popconfirm>
          <el-popconfirm v-if="scope.row.state == 2" title="确认取消发布?" @confirm="unpublished(scope.row)">
            <el-button slot="reference" type="text" size="small">禁用</el-button>
          </el-popconfirm>
          <el-button type="text" size="small" @click="toFarming(scope.row)">农事操作</el-button>
          <!-- <el-button v-if="scope.row.state == 2" type="text" size="small" @click="toLand(scope.row)">关联地块</el-button> -->
        </template>
      </el-table-column>
    </el-table>

    <div class="pagenation">
      <el-pagination
        v-if="pageInfo.totalPage > 0"
        background
        @current-change="handleSizeChange"
        :current-page.sync="pageInfo.currentPage"
        :page-size="pageInfo.pageSize"
        layout="total, prev, pager, next"
        :total="pageInfo.totalPage"
      >
      </el-pagination>
    </div>
    <createPlanting :currentItem="currentItem" :dialogVisible.sync="dialogVisible" :isEdit="isEdit" :isDisable="isDisable" @updateList="getList">
    </createPlanting>
  </div>
</template>

<script>
import createPlanting from '../../../components/createPlanting.vue';
import { plantingList1, unpublishedPlanting, publishPlanting, seasonList, categoryList, deletePlantings } from '@/api/api.js';
import dayjs from 'dayjs';
export default {
  components: {
    createPlanting
  },
  data() {
    return {
      dialogVisible: false,
      isEdit: false,
      isDisable: false,
      title: '',
      applytDate: [],
      startDate: [],
      currentId: '',
      contractName: '',
      searchParams: {
        state: '',
        name: ''
      },
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      options: [
        { value: '1', label: '待启用' },
        { value: '2', label: '可用' },
        { value: '3', label: '不可用' },
        { value: '4', label: '已删除' }
      ],
      tableData: [],
      season: null,
      category: null,
      currentItem: null
    };
  },
  created() {},
  mounted() {
    this.getList();
    this.getSeason();
    this.getCategoryList();
  },
  methods: {
    geetStatus(state) {
      // 1待启用、2可用、3不可用、4已删除
      let val = '';
      switch (state) {
        case 1:
          val = '待启用';
          break;
        case 2:
          val = '可用';
          break;
        case 3:
          val = '不可用';
          break;
        case 4:
          val = '已删除';
          break;
        default:
          break;
      }
      return val;
    },
    getSeasonDetail(key) {
      let val = '';
      this.season.forEach((e) => {
        //console.log(e);
        if (e.id == key) {
          //console.log(e.name);
          val = e.name;
        }
      });
      return val;
    },
    getCategoryDetail(key) {
      let val = '';
      this.category &&
        this.category.forEach((e) => {
          //console.log(e);
          if (e.id == key) {
            val = e.name;
          }
        });
      return val;
    },
    getSeason() {
      seasonList()
        .then((res) => {
          this.season = res;
        })
        .catch((error) => {});
    },

    getCategoryList() {
      this.options1 = [];
      categoryList()
        .then((res) => {
          this.category = res;
        })
        .catch((error) => {});
    },
    toDetail(item) {
      this.currentId = item.id;
      this.isEdit = false;
      (this.isDisable = true), (this.dialogVisible = true);
    },
    toFarming(item) {
      this.$router.push({ name: 'farming', query: { id: item.id, state: item.state } });
    },
    toLand(item) {
      this.$router.push({ name: 'land', query: { id: item.id } });
    },

    search() {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    toEdit(item) {
      this.currentItem = item;
      this.isEdit = true;
      (this.isDisable = false), (this.dialogVisible = true);
    },
    publish(item) {
      let formData = new FormData();
      formData.append('id', item.id);
      publishPlanting(formData)
        .then((res) => {
          this.$message({
            message: '发布成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },

    deletePlant(item) {
      let formData = new FormData();
      formData.append('id', item.id);
      deletePlantings(formData)
        .then((res) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    unpublished(item) {
      let formData = new FormData();
      formData.append('id', item.id);
      unpublishedPlanting(formData)
        .then((res) => {
          this.$message({
            message: '取消发布成功',
            type: 'success'
          });
          this.pageInfo.currentPage = 1;
          this.getList();
        })
        .catch((error) => {});
    },
    handleClick(row) {
      this.pageInfo.currentPage = 1;
      this.getList();
    },
    getList() {
      plantingList1({ pageNum: this.pageInfo.currentPage, pageSize: this.pageInfo.pageSize, ..._.pickBy({ ...this.searchParams }) })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    confirmApply(e) {
      //console.log(e);
    }
  }
};
</script>
<style scoped lang="scss">
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
</style>
