<template>
  <div>
    <div class="header">
      <div class="header-title">
        {{ title }}
        <div class="select">
          <el-select v-model="plant" placeholder="请选择" style="width: 100px" @change="plantChange">
            <el-option v-for="item in options" :key="'###' + item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>
      </div>
      <div v-if="!buttons.length" class="no-data">
        <img style="500px;height: 500px" src="@/assets/empty.jpeg" alt="" />
      </div>
      <div v-else>
        <div class="flex-row">
          <!-- <svg class="icon icon-font" aria-hidden="true" @click="toScrollLeft">
            <use xlink:href="#icon-fuzhujiantou-zuo"></use>
          </svg> -->
          <img class="img-font" src="@/assets/left-homework.png" @click="toScrollLeft" alt="" />

          <div class="top-row mrr24" ref="scrollDiv">
            <div ref="div1"></div>
            <div class="flex-row" v-for="(item, index) in buttons" :key="item.createTime">
              <div :class="{ 'type-selected': currentId == item.id }" class="type-btn" @click="btnClick($event, item)">
                {{ item.farmingTypeName }}
              </div>
              <img v-if="index < buttons.length - 1" style="width: 67px; height: 5px" src="@/assets/line-homework.png" alt="" />
            </div>
          </div>

          <img class="img-font" src="@/assets/right-homework.png" @click="toScrollRight" alt="" />
          <!-- <svg class="icon icon-font" aria-hidden="true" @click="toScrollRight">
            <use xlink:href="#icon-fuzhujiantou-you"></use>
          </svg> -->
        </div>

        <div class="flex-row-around">
          <div id="chat1" class="plant-chart"> </div>
          <div id="chat2" class="plant-chart"> </div>
        </div>
        <div class="flex-row-around" style="margin-top: 24px">
          <div id="chat3" class="plant-chart"> </div>
          <div id="chat4" class="plant-chart"> </div>
        </div>

        <div class="flex-row">
          <el-radio-group v-model="radio1" class="mr24" @change="radioChange">
            <el-radio-button label="方案任务详情"></el-radio-button>
            <el-radio-button label="地块任务详情"></el-radio-button>
          </el-radio-group>
          <div style="flex: 1"></div>
          <div v-show="radio1 == '方案任务详情'" class="to-map" @click="showMapInfo"> <img src="@/assets/map_icon.png" alt="" /> 地图查看</div>
        </div>
        <el-table v-if="radio1 == '方案任务详情'" :data="tableData" key="方案任务详情" stripe class="mr24">
          <template slot="empty">
            <img style="width: 500px; height: 500px" src="@/assets/empty.jpeg" alt="" />
          </template>
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column prop="ownerName" label="地块负责人"> </el-table-column>
          <!-- 春娇说陈总说的不要了@2023年03月21日09:42:52 -->
          <!-- <el-table-column prop="planingTime" label="规划时间">
      </el-table-column> -->
          <el-table-column prop="statisticPlantCount" label="应用地块"> </el-table-column>
          <el-table-column prop="statisticCompletedPlantCount" label="已完成地块"> </el-table-column>
          <el-table-column prop="statisticPlantArea" label="应用地块总面积(亩)">
            <template slot-scope="scope">
              {{ formatPlantArea(scope.row.statisticPlantArea) }}
            </template>
          </el-table-column>
          <el-table-column prop="statisticCompletedPlantArea" label="已完成面积(亩)">
            <template slot-scope="scope">
              {{ formatPlantArea(scope.row.statisticCompletedPlantArea) }}
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="toFarming(scope.row)">查看详情</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-row v-if="radio1 == '地块任务详情'">
          <el-col :span="6" class="flex-row-s">
            <span class="span1">地块名称</span>
            <el-input v-model="searchParams.landName" style="width: 60%" placeholder="请输入内容" clearable></el-input>
          </el-col>
          <el-col :span="6" class="flex-row-s">
            <span class="span1">状态</span>
            <el-select style="width: 60%" v-model="searchParams.state" placeholder="请选择" clearable>
              <el-option v-for="item in statusOption" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-col>
          <el-col :span="6" class="flex-row-s">
            <span class="span1">负责人</span>
            <el-select style="width: 60%" v-model="searchParams.ownerId" placeholder="请选择" clearable>
              <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-col>
          <el-col :span="6" class="flex-row-s">
            <el-button type="primary" @click="search">搜索</el-button>
          </el-col>
        </el-row>
        <el-table v-if="radio1 == '地块任务详情'" :data="tableData" stripe key="地块任务详情" class="mr24">
          <el-table-column key="index" type="index" label="序号"></el-table-column>
          <el-table-column prop="landName" label="地块名称"> </el-table-column>
          <!-- <el-table-column prop="planingTime" label="规划时间">
      </el-table-column> -->

          <el-table-column prop="landArea" width="250" label="作业面积/应用地块总面积(亩)">
            <template slot-scope="scope"> {{ formatPlantArea(scope.row.completedArea) }}/{{ formatPlantArea(scope.row.plantArea) }} </template>
          </el-table-column>
          <el-table-column key="ownerName" prop="ownerName" label="地块负责人"> </el-table-column>
          <el-table-column prop="taskStateName" label="作业状态"></el-table-column>

          <el-table-column prop="ownerName" label="农事操作时间" width="200">
            <template slot-scope="scope"> {{ scope.row.startDate || '-' }}/{{ scope.row.endDate || '-' }} </template>
          </el-table-column>
          <el-table-column prop="ownerName" label="农资种类">
            <template slot-scope="scope">
              <span class="means-num" v-if="scope.row.productionRecords && scope.row.productionRecords.length" @click="showRecords(scope.row)">{{
                productionRecordsName(scope.row.productionRecords)
              }}</span>
              <span v-else>-</span>
            </template>
          </el-table-column>

          <el-table-column label="农机数量">
            <template slot-scope="scope">
              <span
                v-if="
                  (scope.row.machineRecords && scope.row.machineRecords.length) ||
                  (scope.row.machineOfOutsideRecords && scope.row.machineOfOutsideRecords.length)
                "
                class="means-num"
                @click="showMachineRecords(scope.row)"
              >
                <template v-if="scope.row.machineRecords && scope.row.machineOfOutsideRecords">
                  {{ scope.row.machineRecords.length + scope.row.machineOfOutsideRecords.length }}
                </template>
                <template v-else-if="scope.row.machineRecords && !scope.row.machineOfOutsideRecords">
                  {{ scope.row.machineRecords.length }}
                </template>
                <template v-else-if="!scope.row.machineRecords && scope.row.machineOfOutsideRecords">
                  {{ scope.row.machineOfOutsideRecords.length }}
                </template>
              </span>
              <span v-else>0</span>
            </template>
          </el-table-column>
          <el-table-column label="图片">
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.imageUrls && scope.row.imageUrls.length"
                style="width: 100px; height: 100px"
                :src="scope.row.imageUrls[0]"
                :zoom-rate="1.2"
                :preview-src-list="scope.row.imageUrls"
                fit="cover"
              />
            </template>
          </el-table-column>
          <el-table-column prop="descriptions" label="备注" show-overflow-tooltip> </el-table-column>
        </el-table>
        <div class="flex-center">
          <el-pagination
            v-if="pageInfo.totalPage > 0"
            background
            @current-change="handleSizeChange"
            :current-page.sync="pageInfo.currentPage"
            :page-size="pageInfo.pageSize"
            layout="total, prev, pager, next"
            :total="pageInfo.totalPage"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="方案任务详情" :close-on-click-modal="false" :close-on-press-escape="false" width="1000px" :visible.sync="mapModeDgShow">
      <plantStatus :schemeFarmingId="currentId" :land-dg-visible="true" @closeLandDg="closeLandDg"></plantStatus>
    </el-dialog>
    <productRecords
      :records="records"
      :resourceTypeDic="resourceTypeDic"
      :showRecordsFlag.sync="showRecordsFlag"
      v-if="showRecordsFlag"
    ></productRecords>
    <machineRecords :recordsItem="records" :showRecordsFlag.sync="showMachineRecordsFlag" v-if="showMachineRecordsFlag"></machineRecords>
  </div>
</template>

<script>
import { plantingHomepage, farmingChart, plantingDetail, plantingOwnerDetail, sysUserPage } from '@/api/api.js';
import productRecords from '@/view/components/productRecords.vue';
import machineRecords from '@/view/components/machineRecords.vue';
import * as echarts from 'echarts';
import { nextTick } from 'vue';
import plantStatus from './components/plantStatus.vue';
import * as utils from '@/utils/utils.js';
export default {
  components: {
    plantStatus,
    productRecords,
    machineRecords
  },
  data() {
    return {
      showRecordsFlag: false,
      showMachineRecordsFlag: false,
      resourceTypeDic: {},
      records: [],
      title: '',
      statusOption: [
        { id: 1, name: '未开始' },
        { id: 2, name: '进行中' },
        { id: 3, name: '已完成' },
        { id: 4, name: '已忽略' },
        { id: 5, name: '已取消' }
      ],
      pageInfo: {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      },
      plant: '',
      currentId: '',
      tableData: [],
      options: [],
      radio1: '方案任务详情',
      value: '',
      buttons: [],
      searchParams: { landName: '', state: '' },
      scollIndex: 0,
      ownerId: '',
      mapModeDgShow: false,
      userList: [],
      recordsItem: null
    };
  },
  created() {},
  mounted() {
    this.getInitData();
    this.getPersonList();
    this.getDicList();
  },
  watch: {
    currentId: {
      handler(newValue, oldValue) {
        this.pageInfo = {
          currentPage: 1,
          pageSize: 15,
          totalPage: 0
        };
        if (newValue) {
          this.getChartData();
          this.getList();
        }
      },
      deep: true
    }
  },
  methods: {
    productionRecordsName(list) {
      let nameList = list.map((e) => {
        return e.agriculturalProductionName;
      });
      let nameStr = nameList.join(',');
      let res = '';
      if (nameStr.length > 5) {
        res = nameStr.substr(0, 4) + '...';
      } else {
        res = nameStr;
      }
      return res;
    },
    formatPlantArea(val) {
      return utils.formatArea(val);
    },
    async getDicList() {
      let res = await this.$store.dispatch('getDicAll');

      this.resourceTypeDic = res;
    },
    showRecords(row) {
      this.records = row.productionRecords || [];
      this.showRecordsFlag = true;
    },
    showMachineRecords(row) {
      this.records = row;
      this.showMachineRecordsFlag = true;
    },
    getPersonList() {
      sysUserPage().then((res) => {
        this.userList = res.rows;
      });
    },
    // getState(state) {
    //   for (let index = 0; index < this.statusOption.length; index++) {
    //     const e = this.statusOption[index];
    //     if (e.id == state) {
    //       //console.log('??? ');
    //       return e.name;
    //     }
    //   }
    // },
    showMapInfo() {
      this.mapModeDgShow = true;
    },
    toFarming(item) {
      this.ownerId = item.ownerId;
      this.radio1 = '地块任务详情';
      this.getTable2();
    },
    search() {
      this.getTable2();
    },
    radioChange(e) {
      this.pageInfo = {
        currentPage: 1,
        pageSize: 15,
        totalPage: 0
      };
      this.searchParams = { landName: '', state: '' };
      this.ownerId = '';
      if (e == '方案任务详情') {
        this.getTable1();
      } else {
        this.getTable2();
      }
      //console.log(e);
    },
    getTable2() {
      plantingDetail({
        pageNum: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
        schemeFarmingId: this.currentId,
        ownerId: this.ownerId,
        ..._.pickBy({ ...this.searchParams })
      })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    getTable1() {
      plantingOwnerDetail({ schemeFarmingId: this.currentId, pageNum: this.pageInfo.currentPage, pageSize: this.pageInfo.pageSize })
        .then((res) => {
          this.tableData = res.rows;
          this.pageInfo.totalPage = res.total;
        })
        .catch((error) => {});
    },
    getChartData() {
      farmingChart({ schemeFarmingId: this.currentId })
        .then((res) => {
          nextTick(() => {
            this.getEahrt1Date(res);
            this.getEahrt2Date(res);
            this.getEahrt3Date(res);
            this.getEahrt4Date(res);
          });
        })
        .catch((error) => {});
    },
    btnClick(event, data) {
      this.currentId = data.id;
      this.title = data.name;
      const item = event.target;
      const itemLeft = item.offsetLeft;
      const scrollLeft = this.$refs.scrollDiv.scrollLeft;
      const containerWidth = this.$refs.scrollDiv.clientWidth;
      const itemWidth = item.clientWidth;

      const center = scrollLeft + containerWidth / 2;
      const left = itemLeft - containerWidth / 2 - itemWidth / 2;

      this.$refs.scrollDiv.scroll({
        left: left,
        behavior: 'smooth'
      });
    },
    plantChange() {
      this.$nextTick((item) => {
        this.options.map((e) => {
          if (e.id == this.plant) {
            //console.log(e.tasks);
            if (e.tasks && e.tasks.length) {
              this.buttons = e.tasks;
              this.currentId = this.buttons[0].id;
              this.title = this.buttons[0].name;
            } else {
              this.buttons = [];
              this.title = '';
              this.currentId = '';
            }
          }
        });
      });
    },
    getInitData() {
      plantingHomepage()
        .then((res) => {
          let list = res.schemes.map((e) => {
            let item = {};
            item.name = e.name;
            item.id = e.id;
            item.tasks = e.details;
            return item;
          });
          if (list && list.length) {
            this.options = list;
            this.plant = list[0].id;
            this.buttons = list[0].tasks || [];
            if (this.buttons.length) {
              this.currentId = this.buttons[0].id;
              this.title = this.buttons[0].name;
            }
          }
        })
        .catch((error) => {});
    },
    toScrollRight() {
      if (this.scollIndex > this.buttons.length) {
        return;
      }
      this.scollIndex++;
      this.$refs.scrollDiv.scrollTo({
        left: this.scollIndex * 230 * 3
      });
    },
    toScrollLeft() {
      if (this.scollIndex < 0) {
        return;
      }
      this.scollIndex--;
      this.$refs.scrollDiv.scrollTo({
        left: this.scollIndex * 230 * 3
      });
    },
    getList() {
      if (this.radio1 == '方案任务详情') {
        this.getTable1();
      } else {
        this.getTable2();
      }
    },
    handleSizeChange(e) {
      this.pageInfo.currentPage = e;
      this.getList();
    },
    getEahrt1Date(data) {
      let option = {
        color: ['#F6BD16', '#5B8FF9'],
        title: [
          {
            text: '总计完成面积占比'
          },
          {
            text: '总面积',
            x: 'center',
            top: '40%'
          },
          {
            text: data.totalArea + '亩',
            x: 'center',
            top: '50%'
          }
        ],
        tooltip: {
          trigger: 'item'
        },
        label: {
          formatter: '{name|{b}}\n{time|{c} 亩}',
          minMargin: 5,
          edgeDistance: 10,
          lineHeight: 15,
          rich: {
            time: {
              fontSize: 10,
              color: '#999'
            }
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            data: [
              { value: utils.formatArea(data.completedArea), name: '已完成' },
              { value: utils.formatArea(data.unCompletedArea), name: '未开始' }
            ]
          }
        ]
      };

      this.setChart('chat1', option);
    },

    getEahrt2Date(data) {
      let list1 = [];
      let list2 = [];
      data.ownerStatisticViewVOList.map((e) => {
        list1.push(e.unCompletedAreaOfUser);
        list2.push(e.completedAreaOfUser);
      });

      var echartsData = {
        title: 'title',
        city: data.ownerStatisticViewVOList.map((e) => {
          return e.ownerName;
        }),
        legend: ['未开始', '已完成'],
        data: [list1, list2]
      };
      var colors = ['#5B8FF9', '#F6BD16', '#61DDAA'];
      var option = {
        title: [
          {
            text: '各负责人完成面积占比'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        toolbox: {
          iconStyle: {
            borderColor: '#fff',
            fontSize: '22px'
          }
        },
        color: colors,
        legend: {
          top: 10,
          right: 10,
          itemWidth: 10,
          itemHeight: 10,
          padding: [5, 10],
          textStyle: {
            fontSize: 12,
            color: '#333',
            padding: [3, 0, 0, 0]
          },
          data: echartsData.legend
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '7%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#96A4F4',
            rotate: 0 //倾斜度
          },
          axisLine: {
            lineStyle: {
              color: '#96A4F4'
            },
            width: 5
          },
          axisTick: {
            show: false
          },
          data: echartsData.city
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#96A4F4'
          },
          axisLine: {
            lineStyle: {
              color: '#96A4F4'
            },
            width: 5
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(150, 164, 244, 0.3)'
            }
          }
        },
        series: []
      };
      for (var i = 0; i < echartsData.legend.length; i++) {
        option.series.push({
          name: echartsData.legend[i],
          type: 'bar',
          stack: '总数',
          barWidth: '45%',
          label: {
            show: false,
            position: 'insideRight'
          },
          data: echartsData.data[i]
          // itemStyle: {
          //   normal: {
          //     label: {
          //       show: true, //开启显示
          //       position: 'top', //在上方显示
          //       textStyle: {
          //         //数值样式
          //         color: '#fff',
          //         fontSize: 14,
          //         fontWeight: 600
          //       }
          //     }
          //   }
          // }
        });
      }
      this.setChart('chat2', option);
    },
    getEahrt3Date(data) {
      if (!data.totalPlantNums) {
        data.totalPlantNums = 0;
      }
      let option = {
        color: ['#65789B', '#61DDAA', '#F6BD16', '#5B8FF9'],
        title: [
          {
            text: '总计完成地块占比'
          },
          {
            text: '总地块',
            x: 'center',
            top: '40%'
          },
          {
            text: data.totalPlantNums + '块',
            x: 'center',
            top: '50%'
          }
        ],
        tooltip: {
          trigger: 'item'
        },
        label: {
          formatter: '{name|{b}}\n{time|{c} 块}',
          minMargin: 5,
          edgeDistance: 10,
          lineHeight: 15,
          rich: {
            time: {
              fontSize: 10,
              color: '#999'
            }
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '70%'],
            data: [
              { value: data.ignoredPlantNums, name: '已忽略' },
              { value: data.proceedingPlantNums, name: '进行中' },
              { value: data.completedPlantNums, name: '已完成' },

              { value: data.unStartedPlantNums, name: '未开始' }
            ]
          }
        ]
      };

      this.setChart('chat3', option);
    },

    getEahrt4Date(data) {
      let list1 = [];
      let list2 = [];
      let list3 = [];
      let list4 = [];
      data.ownerStatisticViewVOList.map((e) => {
        list1.push(e.unStartedPlantNumsOfUser);
        list2.push(e.completedPlantNumsOfUser);
        list3.push(e.proceedingPlantNumsOfUser);
        list4.push(e.ignoredPlantNumsOfUser);
      });
      var echartsData = {
        title: 'title',
        city: data.ownerStatisticViewVOList.map((e) => {
          return e.ownerName;
        }),
        legend: ['已忽略', '进行中', '已完成', '未开始'],
        data: [list4, list3, list2, list1]
      };
      var colors = ['#65789B', '#61DDAA', '#F6BD16', '#5B8FF9'];
      var option = {
        title: [
          {
            text: '各负责人完成地块占比'
          }
        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        toolbox: {
          feature: {
            saveAsImage: {
              backgroundColor: '#243a55'
            }
          },
          iconStyle: {
            borderColor: '#fff',
            fontSize: '22px'
          }
        },
        color: colors,
        legend: {
          top: 10,
          right: 10,
          itemWidth: 10,
          itemHeight: 10,
          padding: [5, 10],
          textStyle: {
            fontSize: 12,
            color: '#333',
            padding: [3, 0, 0, 0]
          },
          data: echartsData.legend
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '7%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#96A4F4',
            rotate: 0 //倾斜度
          },
          axisLine: {
            lineStyle: {
              color: '#96A4F4'
            },
            width: 5
          },
          axisTick: {
            show: false
          },
          data: echartsData.city
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#96A4F4'
          },
          axisLine: {
            lineStyle: {
              color: '#96A4F4'
            },
            width: 5
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(150, 164, 244, 0.3)'
            }
          }
        },
        series: []
      };
      for (var i = 0; i < echartsData.legend.length; i++) {
        option.series.push({
          name: echartsData.legend[i],
          type: 'bar',
          stack: '总数',
          barWidth: '45%',
          label: {
            show: false,
            position: 'insideRight'
          },
          data: echartsData.data[i]
          // itemStyle: {
          //   normal: {
          //     label: {
          //       show: true, //开启显示
          //       position: 'top', //在上方显示
          //       textStyle: {
          //         //数值样式
          //         color: '#fff',
          //         fontSize: 14,
          //         fontWeight: 600
          //       }
          //     }
          //   }
          // }
        });
      }
      this.setChart('chat4', option);
    },
    setChart(id, option) {
      var myChart = echarts.init(document.getElementById(id));

      myChart.setOption(option);
    },
    // 关闭圈地弹窗
    closeLandDg() {
      this.mapModeDgShow = false;
    }
  }
};
</script>
<style scoped lang="scss">
.to-map {
  width: 108px;
  height: 36px;
  background: #f2f3f5;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7e88a8;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 14px;
    height: 14px;
    margin-right: 12px;
  }
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.top-row {
  flex: 1;
  display: flex;
  flex-direction: row;
  overflow: auto;
  border-radius: 8px;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.top-row::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.btn {
  margin: 24px;
}

.flex-row-around {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.plant-chart {
  width: 46%;
  height: 280px;
  background-color: #f8f8f9;
  padding: 1%;
}

.mr24 {
  margin: 24px;
}
.mrr24 {
  margin: 24px 0;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header-title {
  font-size: 24px;
  text-align: center;
  position: relative;

  .select {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.type-selected {
  background: #02b98f !important;
  color: white !important;
}

.type-btn {
  width: 160px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #e5f8f3;
  border-radius: 8px;
  color: #02b98f;
  font-size: 14px;
  flex-shrink: 0;
  cursor: pointer;
}

.icon-font {
  font-size: 34px;
  color: #02b98f;
  padding: 20px;
  cursor: pointer;
}
.img-font {
  width: 67px;
  height: 18px;
  cursor: pointer;
}

.flex-row-s {
  display: flex;
  flex-direction: row;
  align-items: center;

  .span1 {
    padding-right: 24px;
    font-size: inherit;
    font-size: 12px;
  }
}
.no-data {
  height: 200px;
  line-height: 200px;
  text-align: center;
  font-size: 40px;
}
.means-num {
  color: #409eff;
  cursor: pointer;
}
</style>
